import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filterListOption'];

  filterDropdown = (element) => {
    const searchTerm = element.target.value;
    this.filterListOptionTargets.forEach((item) => {
      if (item.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    });
  };
}
