import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sortableItem', 'sortableList', 'orderInput'];

  static values = {
    minItems: Number,
  };

  connect() {
    this.sortableItemTargets.forEach((item) => {
      this.initializeItem(item);
    });

    this.setupDragAndDropEvents();
  }

  initializeItem(item) {
    this.initializeDraggableItem(item);

    item.querySelectorAll('a, button').forEach((target) => {
      this.initializeNonDraggableElement(target);
    });
  }

  initializeDraggableItem(item) {
    item.addEventListener('dragstart', () => setTimeout(() => item.classList.add('dragging'), 0));
    item.addEventListener('dragend', () => item.classList.remove('dragging'));
  }

  initializeNonDraggableElement(element) {
    element.setAttribute('draggable', false);
    element.addEventListener('dragstart', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }

  setupDragAndDropEvents() {
    this.sortableListTarget.addEventListener('dragenter', (e) => e.preventDefault());

    this.throttledPerformDragging = this.throttle(this.performDragging, 50);
    this.sortableListTarget.addEventListener('dragover', (e) => {
      e.preventDefault();
      this.throttledPerformDragging(e);
    });

    this.sortableListTarget.addEventListener('dragend', () => {
      // eslint-disable-next-line no-param-reassign
      this.orderInputTargets.forEach((input, index) => { input.value = index; });
    });
  }

  throttle(func, limit) {
    let inThrottle;
    return function throttled(...args) {
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => { inThrottle = false; }, limit);
      }
    };
  }

  performDragging(e) {
    e.preventDefault();

    const draggingItem = this.sortableListTarget.querySelector('.dragging');
    const siblings = this.sortableItemTargets.filter((item) => !item.classList.contains('dragging'));

    const nextSibling = siblings.find((sibling) => {
      const rect = sibling.getBoundingClientRect();
      const siblingTop = rect.top;
      const siblingBottom = siblingTop + rect.height;
      const siblingMidPoint = (siblingTop + siblingBottom) / 2;

      return e.clientY <= siblingMidPoint;
    });

    this.sortableListTarget.insertBefore(draggingItem, nextSibling);
    draggingItem.dispatchEvent(new Event('change', { bubbles: true }));
  }

  addItem(event) {
    event.preventDefault();
    const templateContent = document.getElementById('item-template').content;
    let newItem = document.importNode(templateContent, true);

    const newIndex = this.sortableItemTargets.length;

    const tempDiv = document.createElement('div');
    tempDiv.appendChild(newItem);
    tempDiv.innerHTML = tempDiv.innerHTML
      .replace(/__INDEX__/g, newIndex)
      .replace(/__TOTAL_COUNT__/g, newIndex + 1);

    [newItem] = tempDiv.children;

    this.sortableListTarget.appendChild(newItem);
    this.initializeItem(newItem);

    this.element.dispatchEvent(new CustomEvent('itemAdded', { bubbles: true }));
  }

  deleteItem(event) {
    event.preventDefault();

    if (this.minItemsValue && this.minItemsValue > 0) {
      const remainingItems = this.sortableListTarget.querySelectorAll('li').length;
      if (remainingItems === 1) {
        return;
      }
    }

    const item = this.sortableListTarget.querySelector(`[data-id="${event.params.id}"]`);
    item.remove();

    this.element.dispatchEvent(new CustomEvent('itemRemoved', {
      bubbles: true,
    }));
  }
}
