import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filterDropdown', 'filterButton'];

  connect() {
    this.filterButtonTarget.addEventListener('click', this.toggleFilterDropdown.bind(this));
  }

  toggleFilterDropdown() {
    this.filterDropdownTarget.dataset.visible = true;
  }
}
